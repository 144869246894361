
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "referencials-products-search-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let modal = ref();
        let brands = ref([]);
        let productCategoriesL1 = ref([]);
        let productCategoriesL2 = ref([]);
        let productCategoriesL3 = ref([]);
        let productCategoriesL4 = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchProductModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            name           : "",
            barcode        : "",
            brand          : "",
            code           : "",
            categoryL1     : null,
            categoryL2     : null,
            categoryL3     : null,
            categoryL4     : null,
            low_stock_level: null,
            perishable     : false,
            has_variants   : false,
            description    : "",
        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchProductModalRef.value);
        };

        const getCategories = async (level: number = 1) => {
            let params = { level : level, parent_id : null };

            switch (level) {
                case 2:
                    params.parent_id = queryParams.value.categoriesL1;
                    break;

                case 3:
                    params.parent_id = queryParams.value.categoriesL2;
                    break;

                case 4:
                    params.parent_id = queryParams.value.categoriesL3;
                    break;
            }

            const response = await axios.get(ApiRoutes.productCategories.list, { params: params });

            switch (level) {
                case 2:
                    productCategoriesL2.value = response.data;
                    break;

                case 3:
                    productCategoriesL3.value = response.data;
                    break;

                case 4:
                    productCategoriesL4.value = response.data;
                    break;

                default:
                    productCategoriesL1.value = response.data;
                    break;
            }
        };

        const getBrands = async () => {
            let response = await axios.get(ApiRoutes.products.brands);

            brands.value = response.data;
        };

        onMounted(() => {
            getBrands();
            getCategories();

            modal.value = document.getElementById('kt_modal_search_product');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.keys(props.params).forEach((key) => {

                        if (initialValues.hasOwnProperty(key)) {
                            if (typeof initialValues[key] == 'boolean') queryParams.value[key] = Boolean(props.params[key]);
                            else queryParams.value[key] = props.params[key];
                        }
                    });
                }
            });
        });

        return {
            translate,
            modal,
            brands,
            productCategoriesL1,
            productCategoriesL2,
            productCategoriesL3,
            productCategoriesL4,
            getCategories,
            queryParams,
            submit,
            submitButtonRef,
            searchProductModalRef,
        };
    },
});
